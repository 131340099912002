import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";
import { Helmet } from 'react-helmet';
import { toPlainText } from "../lib/helpers";

import PageHeader from "../components/showcase-landing-page-header";
import ShowcaseList from "../components/showcase-list";
import Partners from "../components/fc-partners";
import LatestPosts from "../components/posts-latest";
import CallToAction from "../components/call-to-action";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query ShowcaseCategoryQuery($id: String!) {
    showcaseLanding: sanityShowcaseLanding {
      title
      themePurple
      partners {
        title
        partners {
          _key
          partner {
            name
            slug {
              current
            }
            link
            logo {
              asset {
                extension
                url
                _id
              }
              alt
            }
          }
        }
      }
      callToAction {
        title
        _rawDescription
        button {
          blank
          secondaryButton
          text
          url
        }
      }
    }

    currentCat: sanityShowcaseCategory(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      _rawDescription
      latestPosts {
        ... on SanityLibraryReference {
          _key
          _type
          library {
            title
            slug {
              current
            }
            thumbnail {
              ...SanityImage
              alt
            }
            types {
              _key
              libraryType {
                title
                slug {
                  current
                }
              }
            }
          }
        }
        ... on SanityNewsReference {
          _key
          _type
          news {
            title
            slug {
              current
            }
            publicationDate
            thumbnail {
              ...SanityImage
              alt
            }
          }
        }
      }
      seo {
        title
        description
        thumbnail {
          asset {
            _id
          }
          alt
        }
      }
    }

    showcase: allSanityShowcase(
      sort: { fields: [orderRank], order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          featured
          partner {
            name
            slug {
              current
            }
            type {
              title
            }
            logo {
              asset {
                extension
                url
                _id
              }
              alt
            }
          }
          thumbnail {
            ...SanityImage
            alt
          }
          categories {
            _key
            showcaseCategory {
              id
              title
              slug {
                current
              }
            }
          }
        }
      }
    }

    news: allSanityNews(
      sort: { fields: [publicationDate], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          publicationDate
          thumbnail {
            ...SanityImage
            alt
          }
        }
      }
    }
  }
`;

const ShowcaseCategoryTemplate = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const showcaseLanding = (data || {}).showcaseLanding;
  const currentCat = (data || {}).currentCat;
  const showcase = (data || {}).showcase.edges.filter(post => (post.node.categories.map(({showcaseCategory}) => showcaseCategory.id).includes(currentCat.id)));
  const news = (data || {}).news.edges;

  const title = currentCat.seo?.title || currentCat.title;
  const description = currentCat.seo?.description || toPlainText(currentCat._rawDescription);
  const image = currentCat.seo?.thumbnail;
  const autoSiteTitle = !currentCat.seo?.title;

  return (
    <Layout>
      <Seo
        title={title}
        description={description}
        image={image}
        autoSiteTitle={autoSiteTitle}
      />

      <Helmet>
        <html className={showcaseLanding.themePurple ? "theme-purple" : undefined} lang="en" />
      </Helmet>

      {currentCat.title && (<PageHeader title={currentCat.title} description={currentCat._rawDescription} landingTitle={showcaseLanding.title} />)}
      {showcase && (<ShowcaseList showcase={showcase} />)}
      {showcaseLanding.partners && (<Partners {...showcaseLanding.partners} />)}
      {(news || currentCat.latestPosts) && (<LatestPosts dPosts={news} mPosts={currentCat.latestPosts} />)}
      {showcaseLanding.callToAction && (<CallToAction {...showcaseLanding.callToAction} />)}
    </Layout>
  );
};

export default ShowcaseCategoryTemplate;
